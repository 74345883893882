.container {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

@media print {
  body * {
    visibility: visible !important;
  }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

/*@media all and (min-width: 991px) {
  .phoneImageContent {
    display: block;
  }
}

@media all and (max-width: 990px) {
  .phoneImageContent {
    display: none;
  }
}*/

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.center-panel {
  text-align: center;
  padding: 50px 20px;
  margin: 75px auto 0 auto;
  background-color: var(--surface-a);
}

div.upload {
  width: 157px;
  height: 57px;
  background: url(/assets/upload.png);
  overflow: hidden;
}

div.upload input {
  display: block !important;
  width: 157px !important;
  height: 57px !important;
  opacity: 0 !important;
  overflow: hidden !important;
}

.user-image {
    width: 75px;
    height: 75px;
}

#user-image-circle {
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 200px;
}

.custom-image-style {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

input {
    width: 100%;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center;
}

.center-text-image {
    display: flex;
    align-items: center;
    color: black;

    a:visited {
        color: black;
    }
}

.text-bold {
    font-weight: bold;
    font-size: 14px;
    padding-left: 10px;
}

.custom-labels {
  background-color: #37474F !important;
  color: yellow;
  padding: 3px 15px 3px 15px;
  border: 1px solid #808080;
  font-size: 14px;
  text-align: right;
}

.custom-txtbox {
  padding: 3px 15px 3px 15px;
  border: 1px solid #808080;
  font-size: 14px;
  text-align: right;
}

.highlight {
    color: black;
    padding:2px;
    background-color: #fff2ac;
    background-image: linear-gradient(to right, #ffe359 0%, #fff2ac 100%);
}

.image-content {
    /*border: #808080 solid 1px;*/
    overflow-y: auto;
    height: 100%;
}

.mini-progress-spinner {
    width:30px;
    height:30px;
}

.dialog-security-event-report {
    height: 350px;
}

.dialog-issue-type-textbox {
    width: 400px;
}

.pointer {
    cursor: pointer;
}

.big-icon-red {
  font-size: 3em;
  color: red !important;
}

/*Para cambiar background-color de Priority: High y Medium*/
.priority-high {
    background-color: #fa2552 !important;
    color: white !important;
    font-family: 'Segoe UI' !important;
    font-size: 12px;
    padding: 3px 7px 3px 7px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}
.priority-medium {
    background-color: #ffeb3b !important;
    color: black !important;
    font-family: 'Segoe UI' !important;
    font-size: 12px;
    padding: 3px 7px 3px 7px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}
/*Para cambiar background-color de Priority: High y Medium*/

/*Para cambiar background-color de Days: <= 10 = verde, between 11 and 20 = amarillo, <= 21 = rojo */
.days-high {
    background-color: #fa2552 !important;
    color: white !important;
    font-family: 'Segoe UI' !important;
    font-size: 12px;
    padding: 3px 7px 3px 7px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}

.days-medium {
    background-color: #ffeb3b !important;
    color: black !important;
    font-family: 'Segoe UI' !important;
    font-size: 12px;
    padding: 3px 7px 3px 7px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}

.days-low {
    background-color: #00ff21 !important;
    color: black !important;
    font-family: 'Segoe UI' !important;
    font-size: 12px;
    padding: 3px 7px 3px 7px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}
/*Para cambiar background-color de Days: <= 10 = verde, between 11 and 20 = amarillo, <= 21 = rojo */

.chart-legend-red {
    background-color: #FF6384 !important;
    color: white !important;
    font-family: 'Segoe UI' !important;
    font-size: 15px;
    padding: 5px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}

.chart-legend-yellow {
    background-color: #FFCE56 !important;
    color: white !important;
    font-family: 'Segoe UI' !important;
    font-size: 15px;
    padding: 5px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    -moz-box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
    box-shadow: 0px 2px 10px 0px rgba(5,5,5,0.75);
}

/*Icons buttons colors*/
.icon-delete-color {
    color: red;
    font-size: 35px;
    cursor: pointer;
}

.icon-search-color {
    color: black;
    font-size: 35px;
    cursor: pointer;
}

.icon-file-download-color {
    color: blue;
    font-size: 35px;
    cursor: pointer;
}

p-table {
    font-size: 14px;

    .input-search-frame {
        text-align: right;

        .icon-search {
            margin: 4px 4px 0 0;
        }

        input {
            width: auto;
        }
    }

    .col-button {
        width: 78px;
        text-align: center;
    }

    .col-small {
        width: 120px !important;
    }

    .col-medium {
        width: 250px !important;
    }

    .col-priority {
        width: 95px !important;
    }

    .col-status {
        width: 50px;
        text-align: center;
    }

    .col-two-icons {
        width: 115px;
        text-align: center;
    }.app-dropdown {
    width: 100%;

    .item-text {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    .item-text-left {
        font-size: 14px;
        float: left;
        text-align: left;
    }

    .item-text-right {
        font-size: 14px;
        float: right;
        text-align: right;
    }

    .item-subtext-left {
        font-size: 12px;
        float: left;
        text-align: left;
    }
}
}

p-panel {
    p-header {
        font-size: 16px;
        display: inline-block;
        margin-top: 2px;
    }
}

p-editor {
    p-header {
        color: black;
    }

    .commenttext {
        height: 150px;
    }
}

p-carousel {
    .data-container {
        padding-left: 10px;
        padding-bottom: 10px;
    }

    img {
        vertical-align: middle;
    }

    .img-responsive,
    .thumbnail > img,
    .thumbnail a > img,
    .carousel-inner > .item > img,
    .carousel-inner > .item > a > img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .img-thumbnail {
        display: inline-block;
        max-width: 100%;
        height: auto;
        padding: 4px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

.containerMyComments{
    width:640px;
    height:410px;
    background-color: aqua;
}

.image-app-store {
  width: 150px;
  height: 50px;
}

.link-change-cursor {
    cursor:pointer;
}

.app-chips {
  width: 100%;
}

.p-chips-multiple-container {
  flex-wrap: wrap;
  align-content: space-between;
  padding-bottom: 0;
}

.p-chips-token, .p-chips-input-token {
  margin-bottom: 0.5rem;
}

.title-global-csr-data {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  list-style-type: none;
  text-transform: uppercase;
}
