@mixin custom-badge() {
    padding: 1px 9px 2px;
    font-size: 16.025px;
    font-weight: bold;
    white-space: nowrap;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}

.custom-badge2 {
  padding: 1px 5px 2px;
  font-size: 11px;
  /*font-weight: bold;*/
  white-space: nowrap;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.lab-custom-badge {
    @include custom-badge();
    color: #ffffff;
    background-color: #999999;
}

.col-comment-badge {
    padding: 1px 5px 2px;
    font-size: 11px;
    font-weight: bold;
    white-space: nowrap;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    color: #ffffff;
    background-color: red;
}

.badge-error {
    background-color: red;
}

.badge-warning {
    background-color: #f89406;
}

.badge-success {
    background-color: #1ab394;
}

.badge-info {
    background-color: #3a87ad;
}

.badge-inverse {
    background-color: #333333;
}
