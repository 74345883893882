﻿.mainMenuScrollPanel .ui-scrollpanel-content {
    padding: 0 0 0 0;
}

.normal-value-scroll-panel {
    width: 100%;
    height: 230px;
    padding: 0 0 0 0;
}

.differential-normal-value-scroll-panel {
    width: 100%;
    height: 120px;
    padding: 0 0 0 0;
}

.bill-component-scroll-panel {
    width: 100%;
    height: 230px;
    padding: 0 0 0 0;
}