.ui-dialog {
    overflow: visible;

    .ui-dialog-content {
        overflow: visible;
    }
}

.app-p-dialog {

    font-size: 0.875em !important;

    input, textarea, p-fieldset, p-listbox {
        width: 100%;
    }

    .center-label {
        text-align: center;
    }
}

.app-custom-dialog-col {
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
}

.dialog-compensatory-time {
    width: 500px;
    min-width: 500px;
}